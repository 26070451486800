@import './colors';
@import './sizes';

@mixin horizontal-rule {
    display: block;
    height: 1px;
    width: 100%;
    border-top: 1px solid var(--p-content-border-color);
    border-bottom: 0;
    border-left: 0;
    border-right: 0;
    align-self: stretch;
    border-style: solid;
    margin: $margin 0;
}

%horizontal-rule {
    @include horizontal-rule();
}

%small-print {
    font-weight: 300;
    color: var(--text-color-secondary);
    font-size: 14px;
}

hr {
    &:not(.lt-md) {
        @extend %horizontal-rule;
    }
    &.lt-md {
        display: none;
    }
    &.vertical {
        height: auto;
        width: 1px;
        border-left: 1px solid var(--p-content-border-color);
        border-top: 0;
        align-self: stretch;
        border-style: solid;
        margin: 0 $margin;
    }
}

// <= MEDIUM SCREENS
@media only screen and (max-width: $screenSizeSmall) {
    hr {
        &.lt-md {
            @include horizontal-rule;
        }
    }
}

// // > MEDIUM SCREENS
// @media only screen and (min-width: calc(#{$screenSizeSmall} + 1)) {
//     hr {
//         &.lt-md {
//             display: none;
//             border: none;
//             border-style: solid;
//             height: 0px;
//             width: 0px;
//             padding: 0;
//             margin: 0;
//         }
//     }
// }

.text-small {
    font-size: $font-size-small;
}

.small-print {
    @extend %small-print;
}

.box {
    padding: $padding;
    border-radius: 5px;
    border: 1px solid $box-border-color;
    transition: border-color 150ms ease-in;
    .title-text {
        display: flex;
        align-items: center;
    }

    &.danger {
        border-color: var(--text-color-error);
        transition: border-color 150ms ease-in;
        strong.danger {
            color: var(--text-color-error);
        }
    }
}

.vr {
    background-color: $box-border-color;
    width: 1px;
}

.status-icon {
    &.checked {
        color: var(--primary-color);
    }
}

.code-card,
.code-dropped-card {
    &.archived {
        .p-card {
            background-color: var(--surface-archived);
        }
    }
    &.hidden {
        display: none !important;
    }
}

.archived-toggle {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    .field {
        display: flex;
        align-items: center;
        margin-bottom: $margin-small;
        label {
            margin-right: $margin-small;
        }
    }
}
