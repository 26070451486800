@tailwind base;
@tailwind components;
@tailwind utilities;

@import '../scss/theme';

:root {
    --html-background-color: var(--p-surface-0);
    /* PrimeFlex applies this color variable to the required * on form fields */
    --red-500: var(--p-red-500);
}

:root,
html.pi-theme-light {
    /* PrimeNg does not export the error color as a variable, so we define it ourselves. */
    /* https://github.com/primefaces/primeng-sass-theme/blob/17928d8be7a186e0fdf8940179f114c586dc9dad/themes/saga/_variables.scss */
    --text-color-error: var(--red-600);
    --text-color-success: var(--green-600);
    --border-error: var(--text-color-error);
    --surface-error: #ffcdd2;
    --surface-archived: #ffffe2;
    --text-link-color: var(--p-blue-600);
    --text-link-hover-color: var(--p-blue-800);
}

html {
    &.pi-theme-dark {
        --text-link-color: var(--p-blue-300);
        --text-link-hover-color: var(--p-blue-400);
        --html-background-color: var(--p-surface-950);
        --surface-archived: color-mix(in srgb, var(--p-yellow-500), var(--p-surface-950) 70%);
    }
}

html,
body {
    @apply font-sans;
    font-weight: 400;
    font-size: 16px;

    vertical-align: baseline;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

html {
    background-color: var(--html-background-color);
}

.max-width-standard {
    max-width: $max-width-standard;
}

.no-visibility {
    visibility: hidden !important;
}

%info-text {
    padding: 15px;
    background-color: var(--surface-50);
    border: 1px solid var(--p-content-border-color);

    border-radius: 5px;

    font-size: 0.9em;
    color: var(--text-color);
}

.helper-text {
    @extend %info-text;
    font-style: italic;
}

.info-text {
    @extend %info-text;
}

.section-icon {
    font-size: $font-size-extra-large;
    color: var(--text-color);
}

.info-link {
    font-weight: 500;
    &.nowrap {
        white-space: nowrap;
    }
}

.help-overlay {
    max-width: 500px;
}

.hidden {
    display: none !important;
}

// Make the link always blue and react to hover
.link-color,
.link-color:visited {
    color: var(--text-link-color);
}

.link-color-hover-only:hover,
.link-color:hover,
.link-color:active {
    color: var(--text-link-hover-color);
}

// makes content only visible to screen readers
.sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    word-wrap: normal;
    border: 0;
}

// TODO delete the following primeflex classes
.field {
    margin-bottom: 1rem;
}
.field > label {
    display: inline-block;
    margin-bottom: 0.5rem;
}
.field.grid > label {
    display: flex;
    align-items: center;
}
.field > small {
    margin-top: 0.25rem;
}
.field.grid {
    margin-top: 0;
}
.field.grid .col-fixed,
.field.grid .col,
.field.grid .col-1,
.field.grid .col-2,
.field.grid .col-3,
.field.grid .col-4,
.field.grid .col-5,
.field.grid .col-6,
.field.grid .col-7,
.field.grid .col-8,
.field.grid .col-9,
.field.grid .col-10,
.field.grid .col-11,
.field.grid .col-12 {
    padding-top: 0;
    padding-bottom: 0;
}
.field-checkbox {
    margin-bottom: 1rem;
    display: flex;
    align-items: center;
}
.field-checkbox > label {
    margin-left: 0.5rem;
    line-height: 1;
}
