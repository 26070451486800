@import './colors';

$font-size-extra-large: 1.5rem;
$font-size-large: 1.25rem;
$font-size: 1rem;
$font-size-small: 0.85rem;

.title-text {
    font-weight: 600;
    font-size: $font-size-large;
    color: var(--text-color);
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif,
        'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
}
