$base: 20px;

$margin: $base;
$margin-small: calc(#{$base}/ 2);
$margin-large: calc(#{$base}* 1.25);

$padding: $base;

$single-centered-width: 840px;

// Screen size definitions
$screenSizeExtraSmall: 320px;
$screenSizeSmall: 576px;
$screenSizeMedium: 768px;
$screenSizeLarge: 992px;
$screenSizeExtraLarge: 1200px;
$screenSizeExtraExtraLarge: 1200px;

// Max Widths
$max-width-small: 300px;
$max-width-standard: 500px;
